import React from "react";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import CrudAward from "../../section/crudaward/CrudAward";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: "0px solid red",
    boxShadow: "none",
  },
}));

export default function Award() {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <CrudAward />
    </Paper>
  );
}
