import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './components/App';
import Theme from './components/Theme';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <ThemeProvider theme={Theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>, document.getElementById('root'));

serviceWorker.unregister();
