import React, { useEffect, useState, forwardRef } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";

import Alert from "@material-ui/lab/Alert";

import VideoCallIcon from '@material-ui/icons/VideoCall';
import PhotoIcon from "@material-ui/icons/Photo";
import Sort from "@material-ui/icons/Sort";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";
import PublishIcon from "@material-ui/icons/Publish";

import MaterialTable from "material-table";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { DropzoneDialog } from "material-ui-dropzone";
import Gallery from "react-photo-gallery";

import Photo from "./Photo";

import ranchoApi from "../../../api/rancho";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  mmbtn: {
    display: "flex",
    alignItems: "center",
  },
  ttext: {
    fontSize: "small",
    paddingLeft: "1vw",
  },
  cover: {
    width: "15vw",
    marginRight: "2vw",
  },
  txtCover: {
    padding: "2px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

var translation = {};
translation["gallery"] = "Galeria";
translation["kangal"] = "Kangal";
translation["specimen"] = "Ejemplar";
translation["championships"] = "Campeonato";
translation["genetics"] = "Genética";



function CrudMultimedia(props) {
  const classes = useStyles();

  const tableIcons = {
    Add: forwardRef((props, ref) => (
      <div className={classes.mmbtn}>
        <VideoCallIcon {...props} ref={ref} />
        <span className={classes.ttext}>Vincular Video</span>
      </div>
    )),
    Photo: forwardRef((props, ref) => (
      <div className={classes.mmbtn}>
        <PhotoIcon {...props} ref={ref} />
        <span className={classes.ttext}>Portada</span>
      </div>
    )),
    Sort: forwardRef((props, ref) => <Sort {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [multimedia, setMultimedia] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [sortImages, setSortImages] = useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    ranchoApi
      .get(`/multimedia?type_reference=${props.type}&id_reference=${props.id}`)
      .then((res) => {
        setMultimedia(res.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      });
  }, [props]);

  useEffect(() => {
    let apiRoute = props.map[`${props.type}`];
    ranchoApi
      .get(`/multimedia/cover/${apiRoute}/${props.id}`)
      .then((res) => {
        //setSelectedCover(res.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      });
  }, [props]);

  var columns = [
    { title: "id", field: "id", hidden: true },
    {
      title: "Vista",
      render: (rowData) => (
        <CardMedia
          component='img'
          alt={rowData === undefined ? "" : rowData.title}
          image={
            rowData === undefined
              ? "/static/images/wireframe/square-image.png"
              : rowData.type === "IMG"
              ? `http://cdn.rancholasardillas.com/multimedia/${rowData.url}`
              : `https://img.youtube.com/vi/${rowData.url}/hqdefault.jpg`
          }
          className={classes.large}
        />
      ),
    },
    {
      title: "Tipo",
      field: "type",
      lookup: { IMG: "Imagen", VID: "Video" }, 
      initialEditValue: "VID",
      editable: 'never'
    },
    { title: "Titulo", field: "title" },
    { title: "VideoId", field: "url" },
  ];

  const handleClickOpenSort = () => {
    let sortImagesv2 = multimedia.map((it) => ({
      id: it.id,
      width: 1,
      height: 1,
      src:
        it.type === "IMG"
          ? `http://cdn.rancholasardillas.com/multimedia/${it.url}`
          : `https://img.youtube.com/vi/${it.url}/hqdefault.jpg`,
    }));
    setSortImages([...sortImagesv2]);
    setOpenSort(true);
  };

  const handleCloseSort = () => {
    showLoading();
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let promises = [];
    let idx = 0;
    sortImages.forEach((it) => {
      promises.push(
        ranchoApi
          .post("/multimedia/patch/" + it.id, { idx }, { headers })
          .then(() => {
            setIserror(false);
            setErrorMessages([]);
          })
          .catch((error) => console.log(error))
      );
      idx++;
    });
    setOpenSort(false);
    Promise.all(promises).then((it) => {
      ranchoApi
        .get(`/multimedia?type_reference=${props.type}&id_reference=${props.id}`)
        .then((res) => {
          setMultimedia(res.data);
          showLoading();
        })
        .catch((error) => {
          console.log(`Error ${error}`);
        });
    });
  };

  const showLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const SortablePhoto = SortableElement((item) => <Photo {...item} />);
  const SortableGallery = SortableContainer(({ items }) => (
    <Gallery
      photos={items}
      renderImage={(props) => <SortablePhoto {...props} />}
    />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSortImages(arrayMove(sortImages, oldIndex, newIndex));
  };

  const uploadImages = (images) => {
    showLoading();
    let form = new FormData();
    images.map((it) => form.append("image[]", it));
    const typeToFolder = {
      "gallery": "gallery",
      "specimen": "specimen",
      "kangal": "specimen",
      "championships": "championships",
      "award": "award",
      "genetics": "genetics",
    }
    let folder =typeToFolder[`${props.type}`];
    ranchoApi
      .post(`/multimedia/upload/${folder}/${props.id}`, form, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        let promises = [];
        res.data.map((it) => promises.push(postImage(it.filename)));
        Promise.all(promises).then((it) => {
          setMultimedia([...multimedia, ...it]);
          showLoading();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postImage = (filename) => {
    const typeToFolder = {
      "gallery": "gallery",
      "specimen": "specimen",
      "kangal": "specimen",
      "championships": "championships",
      "award": "award",
      "genetics": "genetics",
    }
    let folder =typeToFolder[`${props.type}`];
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let data = {
      type_reference: props.type,
      id_reference: parseInt(props.id),
      type: "IMG",
      title: "Rancho Las Ardillas",
      url: folder + "/" + filename,
      idx: 999,
    };
    return ranchoApi
      .post("/multimedia", data, { headers })
      .then((res) => ({ ...data, id: res.data.id }))
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowAdd = (newData, resolve) => {
    let errorList = [];
    if (newData.title === undefined) {
      errorList.push("Porfavor ingresa un titulo");
    }
    if (errorList.length < 1) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      newData = {
        ...newData,
        id_reference: props.id,
        type_reference: props.type,
        idx: 0,
      };
      ranchoApi
        .post("/multimedia", newData, { headers })
        .then((res) => {
          let dataToAdd = [...multimedia];
          dataToAdd.unshift({ ...newData, id: res.data.id });
          setMultimedia(dataToAdd);
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch((error) => {
          setErrorMessages([`Server error: ${error}`]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    ranchoApi
      .post("/multimedia/delete/" + oldData.id)
      .then((res) => {
        const dataDelete = [...multimedia];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setMultimedia([...dataDelete]);
        resolve();
      })
      .catch((error) => {
        setErrorMessages([`Server error: ${error}`]);
        setIserror(true);
        resolve();
      });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    let errorList = [];
    if (newData.title === "") {
      errorList.push("Porfavor ingresa un titulo");
    }
    if (newData.description === "") {
      errorList.push("Porfavor ingresa una descripción");
    }

    if (errorList.length < 1) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      ranchoApi
        .post("/multimedia/patch/" + newData.id, newData, { headers })
        .then((res) => {
          const dataUpdate = [...multimedia];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setMultimedia([...dataUpdate]);
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch((error) => {
          setErrorMessages([`Server error: ${error}`]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  return (
    <React.Fragment>
      <Grid
        className={classes.toolGrid}
        container
        direction='row'
        justify='flex-start'
        alignItems='center'>
        <Button startIcon={<PublishIcon />} onClick={() => setOpen(true)}>
          Subir Imagenes
        </Button>
        <Button startIcon={<Sort />} onClick={handleClickOpenSort}>
          Ordenar
        </Button>
      </Grid>

      <Dialog
        fullScreen
        open={openSort}
        onClose={handleCloseSort}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleCloseSort}
              aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Ordena los elementos
            </Typography>
            <Button autoFocus color='inherit' onClick={handleCloseSort}>
              Guardar
            </Button>
          </Toolbar>
        </AppBar>
        <SortableGallery items={sortImages} onSortEnd={onSortEnd} axis={"xy"} />
      </Dialog>

      <DropzoneDialog
        dialogTitle='Subir Imagenes'
        dropzoneText='Arrastra las imagenes a esta zona'
        acceptedFiles={["image/jpeg", "image/png", "image/JPEG"]}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Subir"}
        maxFileSize={5000000}
        open={open}
        filesLimit={30}
        showAlerts={false}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          uploadImages(files);
          setOpen(false);
        }}
        showPreviews={false}
        showPreviewsInDropzone={true}
        showFileNamesInPreview={false}
      />
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "800ms" : "0ms",
        }}
        unmountOnExit>
        <LinearProgress />
      </Fade>
      <div>
        {iserror && (
          <Alert severity='error'>
            {errorMessages.map((msg, i) => {
              return <div key={i}>{msg}</div>;
            })}
          </Alert>
        )}
      </div>
      <MaterialTable
        title={translation[props.type]}
        columns={columns}
        data={multimedia}
        icons={tableIcons}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "Elementos",
          },
          toolbar: {
            nRowsSelected: "{0} elemento(s) seleccionados",
          },
          header: {
            actions: "",
          },
          body: {
            emptyDataSourceMessage: "No hay elementos a mostrar",
            filterRow: {
              filterTooltip: "Filtro",
            },
            editTooltip: "Editar",
            deleteTooltip: "Borrar",
            addTooltip: "Vincular Video",
          }
        }}
        actions={[
          /*{
            icon: tableIcons.Photo,
            tooltip: "Portada",
            onClick: (event, rowData) => handleCoverChange(rowData),
          },*/
        ]}
        options={{
          actionsColumnIndex: -1,
          sorting: true,
          pageSize: 40,
          pageSizeOptions: [20,40,80], 
          addRowPosition: "first"
        }}
        detailPanel={(rowData) => {
          let preview;
          if (rowData.type === "VID") {
            let src = `https://www.youtube.com/embed/${rowData.url}`;
            preview = (
              <iframe
                width='100%'
                height='315'
                src={src}
                title={rowData.title}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            );
          } else {
            preview = (
              <img
                src={`http://cdn.rancholasardillas.com/multimedia/${rowData.url}`}
                alt={rowData.title}
                style={{
                  width: "80vw",
                }}
              />
            );
          }
          return preview;
        }}
      />
    </React.Fragment>
  );
}

export default CrudMultimedia;
