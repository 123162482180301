import React, { useState, useEffect, forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import PermMedia from '@material-ui/icons/PermMedia';
import Sort from '@material-ui/icons/Sort';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ranchoApi from '../../../api/rancho';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  mmbtn:{
    display: 'flex',
    alignItems: 'center',
  },
  ttext:{
    fontSize: 'small',
    paddingLeft: '1vw',
  },
  cursorgrab: {
    cursor: "grab"
  },
}));

function CrudGallery() {
  const classes = useStyles();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    PermMedia: forwardRef((props, ref) => <div className={classes.mmbtn}><PermMedia {...props} ref={ref} /><span className={classes.ttext}  >Multimedia</span></div>),
    Sort: forwardRef((props, ref) => <Sort {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  var columns = [
    {title: "id", field: "id", hidden: true},
    {title: "Correo", field: "email"},
    {title: "Password", field: "password"}
  ]
  

  const [data, setData] = useState([]);
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  useEffect(() => { 
    ranchoApi.get("/user")
        .then(res => {    
            setData(res.data)
         })
         .catch(error => {
             console.log(`Error ${error}`)
         })
  }, [])

  const handleRowAdd = (newData, resolve) => {
    let errorList = []
    if(newData.email === undefined){
      errorList.push("Porfavor ingresa un email")
    }
    if(newData.password === undefined){
      errorList.push("Porfavor ingresa una password")
    }
    if(errorList.length < 1){
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        ranchoApi.post("/user", newData, {headers})
        .then(res => {
            let dataToAdd = [...data];
            dataToAdd.push({...newData, id: res.data.id});
            setData(dataToAdd);
            resolve()
            setErrorMessages([])
            setIserror(false)
        })
        .catch(error => {
            setErrorMessages([`Server error: ${error}`])
            setIserror(true)
            resolve()
        })
    }else{
      setErrorMessages(errorList)
      setIserror(true)
      resolve()
    }
  }


  const handleRowDelete = (oldData, resolve) => {
    
    ranchoApi.post("/user/delete/"+oldData.id)
      .then(res => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve()
      })
      .catch(error => {
        setErrorMessages([`Server error: ${error}`])
        setIserror(true)
        resolve()
      })
  }

  const handleRowUpdate = (newData, oldData, resolve) => {
    let errorList = []
    if(newData.email === undefined){
      errorList.push("Porfavor ingresa un email")
    }
    if(newData.password === undefined){
      errorList.push("Porfavor ingresa una password")
    }
    if(errorList.length < 1){
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      ranchoApi.post("/user/patch/"+newData.id, newData, {headers})
      .then(res => {
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setData([...dataUpdate]);
        resolve()
        setIserror(false)
        setErrorMessages([])
      })
      .catch(error => {
        setErrorMessages([`Server error: ${error}`])
        setIserror(true)
        resolve()
        
      })
    }else{
      setErrorMessages(errorList)
      setIserror(true)
      resolve()

    }
    
  }


  return (
    <React.Fragment>
          <div>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }       
          </div>
            <MaterialTable
              title="Usuarios"
              columns={columns}
              data={data}
              icons={tableIcons}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                      handleRowUpdate(newData, oldData, resolve);
                      
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    handleRowAdd(newData, resolve)
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    handleRowDelete(oldData, resolve)
                  }),
              }}
              localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'Elementos'
                },
                toolbar: {
                    nRowsSelected: '{0} elemento(s) seleccionados'
                },
                header: {
                    actions: ''
                },
                body: {
                    emptyDataSourceMessage: 'No hay elementos a mostrar',
                    filterRow: {
                        filterTooltip: 'Filtro'
                    },
                    editTooltip: 'Editar',
                    deleteTooltip: 'Borrar'
                }
            }}
            options={{
              actionsColumnIndex: -1,
              pageSize: 40,
              pageSizeOptions: [20,40,80], 
              addRowPosition: "first"
            }}
            />
    </React.Fragment>
  );
}

export default CrudGallery;