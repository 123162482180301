import React from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import StarIcon from '@material-ui/icons/Star';
import PetsIcon from '@material-ui/icons/Pets';
//import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import WebIcon from '@material-ui/icons/Web';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Header() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <AppBar
        position='absolute'
        className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}>
            <MenuIcon />
          </IconButton>
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            className={classes.title}>
            Administración
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button component="a" href="http://www.rancholasardillas.com/">
            <Tooltip title="Sitio">
              <ListItemIcon>
                <WebIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary='Sitio' />
          </ListItem>
          <ListItem button component={Link} to='/genetics'>
            <Tooltip title="Genética">
              <ListItemIcon>
                <FingerprintIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary='Genética' />
          </ListItem>
          <ListItem button component={Link} to='/gallery'>
            <Tooltip title="Galerias">
              <ListItemIcon>
                <PhotoLibraryIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary='Galerias' />
          </ListItem>
          <ListItem button component={Link} to='/specimens'>
            <Tooltip title="Ejemplares">
              <ListItemIcon>
                <GroupWorkIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary='Ejemplares' />
          </ListItem>
          <ListItem button component={Link} to='/championships'>
            <Tooltip title="Campeonatos">
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary='Campeonatos' />
          </ListItem>
          <ListItem button component={Link} to='/kangal'>
            <Tooltip title="Kangal">
              <ListItemIcon>
                <PetsIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary='Kangal' />
          </ListItem>
          <ListItem button component={Link} to='/user'>
            <Tooltip title="Usuarios">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary='Usuarios' />
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
}
