import React from 'react'

export class NoMatch extends React.Component {

  render() {
      return (
        <React.Fragment>
             <h1><span>Page Not Found</span></h1>
        </React.Fragment>
      );
  }
}