import React from 'react'

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import CrudMultimedia from '../../section/crudmultimedia/CrudMultimedia';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    border: '0px solid red',
    boxShadow: 'none',
  }
}));

export default function Multimedia({ match }) {
    const {
        params: { type, id }
    } = match;

    const classes = useStyles();

    const typeToApiRoute = {
      "gallery": "galleries",
      "specimen": "specimens",
      "kangal": "specimens",
      "championships": "championships",
      "award": "awards",
      "genetics": "genetics",
    }

    return (
      <Paper className={classes.paper}>
        <CrudMultimedia type={type} id={id} map={typeToApiRoute} />
      </Paper>
    );
}