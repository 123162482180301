import React, {useState} from "react";
import {
  useHistory,
  useLocation
} from "react-router-dom";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

import ranchoApi from '../../../api/rancho';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: "0px solid red",
    boxShadow: "none",
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Prento
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  let { from } = location.state || { from: { pathname: "/" } };

  async function handleSubmit(event) {
    localStorage.removeItem('SESSION_RANCHO');
    event.preventDefault();
    let errorList = []
    if(email === ""){
      errorList.push("Porfavor ingresa un email")
    }
    if(password === ""){
      errorList.push("Porfavor ingresa una password")
    }
    if(errorList.length < 1){
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        await ranchoApi.post("/user/login", {email, password}, {headers})
        .then(res => {
            window.localStorage.setItem('SESSION_RANCHO', res.data.session);
            history.replace(from);
        })
        .catch(error => {
            setErrorMessages([`Acceso Denegado ${error}`])
            setIserror(true)
        })
    }else{
      setErrorMessages(errorList)
      setIserror(true)
    }
  };

  let onEmailChange = (e) =>{
    setEmail(e.target.value);
  };
  let onPasswordChange = (e) =>{
    setPassword(e.target.value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div>
        {iserror && 
          <Alert severity="error">
              {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>
              })}
          </Alert>
        }       
      </div>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Admin Panel - Rancho las Adrillas
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={onEmailChange}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Constraseña"
            type="password"
            id="password"
            value={password}
            onChange={onPasswordChange}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
