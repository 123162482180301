import React from 'react'

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import CrudGallery from '../../section/crudgallery/CrudGallery';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    border: '0px solid red',
    boxShadow: 'none',
  }
}));

export default function Gallery() {
    const classes = useStyles();
    return (
      <Paper className={classes.paper}>
        <CrudGallery />
      </Paper>
    );
}