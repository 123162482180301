import React, { useState, useEffect, forwardRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import PermMedia from "@material-ui/icons/PermMedia";
import Sort from "@material-ui/icons/Sort";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ranchoApi from "../../../api/rancho";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";


const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  mmbtn: {
    display: "flex",
    alignItems: "center",
  },
  ttext: {
    fontSize: "small",
    paddingLeft: "1vw",
  },
  cursorgrab: {
    cursor: "grab"
  },
}));

function CrudChampionship() {
  const classes = useStyles();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    PermMedia: forwardRef((props, ref) => (
      <div className={classes.mmbtn}>
        <PermMedia {...props} ref={ref} />
        <span className={classes.ttext}>Multimedia</span>
      </div>
    )),
    Sort: forwardRef((props, ref) => <Sort {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  var columns = [
    { title: "id", field: "id", hidden: true },
    { title: "Titulo", field: "title" },
  ];

  const [data, setData] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [sortItems, setSortItems] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    ranchoApi
      .get("/championship")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      });
  }

  const handleClickOpenSort = () => {
    let items = data.map((it) => ({
      id: it.id,
      value: it.title
    }));
    setSortItems([...items]);
    setOpenSort(true);
  };

  const handleCloseSort = (save) => {
    if (!save){ 
      setOpenSort(false);
      return
    }
    showLoading();
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let promises = [];
    let idx = 0;
    sortItems.forEach((it) => {
      promises.push(
        ranchoApi
          .post("/championship/patch/" + it.id, { idx }, { headers })
          .then(() => {
            setIserror(false);
            setErrorMessages([]);
          })
          .catch((error) => console.log(error))
      );
      idx++;
    });
    setOpenSort(false);
    Promise.all(promises).then(() => {
      getData();
      showLoading();
    });
  };

  const SortableItem = SortableElement(({value}) => <li className={classes.cursorgrab}>{value}</li>);

  const SortableList = SortableContainer(({items}) => {
    return (
      <ol>
        {items.map((item, index) => (
          <SortableItem key={`item-${item.value}`} index={index} value={item.value} />
        ))}
      </ol>
    );
  });

  const onSortEnd = ({oldIndex, newIndex}) => {
    setSortItems(arrayMove(sortItems, oldIndex, newIndex));
  };

  const showLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const handleRowAdd = (newData, resolve) => {
    let errorList = [];
    if (newData.title === undefined) {
      errorList.push("Porfavor ingresa un titulo");
    }
    if (errorList.length < 1) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      newData = { ...newData, idx: 0, cover_id_mm: 0 };
      ranchoApi
        .post("/championship", newData, { headers })
        .then((res) => {
          let dataToAdd = [...data];
          dataToAdd.unshift({ ...newData, id: res.data.id });
          setData(dataToAdd);
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch((error) => {
          setErrorMessages([`Server error: ${error}`]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    ranchoApi
      .post("/championship/delete/" + oldData.id + '?type_reference=championships')
      .then((res) => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve();
      })
      .catch((error) => {
        setErrorMessages([`Server error: ${error}`]);
        setIserror(true);
        resolve();
      });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    let errorList = [];
    if (newData.title === "") {
      errorList.push("Porfavor ingresa un titulo");
    }
    if (newData.description === "") {
      errorList.push("Porfavor ingresa una descripción");
    }

    if (errorList.length < 1) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      ranchoApi
        .post("/championship/patch/" + newData.id, newData, { headers })
        .then((res) => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch((error) => {
          setErrorMessages([`Server error: ${error}`]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  return (
    <React.Fragment>
            <Dialog
        open={openSort}
        onClose={() => handleCloseSort(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Orden de los elementos:"}</DialogTitle>
        <DialogContent>
            <SortableList  id="alert-dialog-description" items={sortItems} onSortEnd={onSortEnd} axis={"xy"}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseSort(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleCloseSort(true)} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "800ms" : "0ms",
        }}
        unmountOnExit>
        <LinearProgress />
      </Fade>
      <div>
        {iserror && (
          <Alert severity='error'>
            {errorMessages.map((msg, i) => {
              return <div key={i}>{msg}</div>;
            })}
          </Alert>
        )}
      </div>
      <MaterialTable
        title='Campeonatos'
        columns={columns}
        data={data}
        icons={tableIcons}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "Elementos",
          },
          toolbar: {
            nRowsSelected: "{0} elemento(s) seleccionados",
          },
          header: {
            actions: "",
          },
          body: {
            emptyDataSourceMessage: "No hay elementos a mostrar",
            filterRow: {
              filterTooltip: "Filtro",
            },
            editTooltip: "Editar",
            deleteTooltip: "Borrar",
          },
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 40,
          pageSizeOptions: [20,40,80], 
          addRowPosition: "first"
        }}
        actions={[
          {
            icon: tableIcons.PermMedia,
            tooltip: "Multimedia",
            onClick: (event, rowData) =>
              (window.location.pathname = "/multimedia/championships/" + rowData.id),
          },
        ]}
      />
            <Button startIcon={<Sort />} onClick={handleClickOpenSort}>
        Ordenar
      </Button>
    </React.Fragment>
  );
}

export default CrudChampionship;
