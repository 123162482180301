
import React from "react";

import { BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Header from "../section/header/Header";
import { Home } from "../page/home/Home";
import Login from "../page/login/Login";
import { NoMatch } from "../page/nomatch/NoMatch";
import User from "../page/user/User";
import Gallery from "../page/gallery/Gallery";
import Multimedia from "../page/multimedia/Multimedia";
import Championship from "../page/championship/Championship";
import Award from "../page/award/Award";
import Genetic from "../page/genetic/Genetic";
import Specimen from "../page/specimen/Specimen";
import Kangal from "../page/kangal/Kangal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function PrivateRoute ({component: Component, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => checkSession()
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

function checkSession(){
  let session = window.localStorage.getItem('SESSION_RANCHO');
  return session !== null && session !== ""
}


export default function MainComponent() {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <Header />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth='lg' className={classes.container}>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/login' component={Login} />
              <PrivateRoute exact path='/user' component={User} />
              <PrivateRoute exact path='/gallery' component={Gallery} />
              <PrivateRoute exact path='/championships' component={Championship} />
              <PrivateRoute exact path='/awards' component={Award} />
              <PrivateRoute exact path='/genetics' component={Genetic} />
              <PrivateRoute exact path='/specimens' component={Specimen} />
              <PrivateRoute exact path='/kangal' component={Kangal} />
              <PrivateRoute
                exact
                path='/multimedia/:type/:id'
                component={Multimedia}
              />
              <Route component={NoMatch} />
            </Switch>
          </Container>
        </main>
      </div>
    </BrowserRouter>
  );
}

